import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Conservators are members of the `}<a parentName="p" {...{
        "href": "/The%20Argent",
        "title": "The Argent"
      }}>{`Argentia`}</a>{` that act as historians and keepers of records and books, both ancient and recent.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      